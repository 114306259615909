<div class="container">
  <div class="row justify-content-center">
    <div *ngIf="!indoor" class="col-xl-6 col-lg-8">
      <form [formGroup]="form">

        <div *ngIf="!indoor" class="box_order_form">
          <div class="head">
            <div class="title">
              <h3>{{ tituloEndereco }}</h3>
            </div>
          </div>

          <div class="main" style="color: black;">
            <div *ngIf="enderecoSelecionado && !retirarNaLoja && !indoor" class="endereco" style="display: flex;">
              <mat-icon class="material-icons-outlined icon-endereco">place</mat-icon>
              <div>
                <h4>{{ enderecoSelecionado.Tipo }}</h4>
                <h4 style="font-weight: 500; font-size: 15px;">{{ enderecoSelecionado.Rua.trim() }},
                  {{ enderecoSelecionado.Numero }}, {{ enderecoSelecionado.Bairro }}, {{ enderecoSelecionado.Municipio }}
                </h4>
                <h4>{{ enderecoSelecionado.Complemento }}</h4>
              </div>
            </div>

            <h4 *ngIf="indoor && !numeroMesa">
              {{ enderecoLoja }}
            </h4>

            <div *ngIf="retirarNaLoja" class="endereco" style="display: flex;">
              <mat-icon class="material-icons-outlined icon-endereco">place</mat-icon>
              <div>
                <h4 style="font-weight: 500; font-size: 15px;">
                  {{ enderecoLoja }}
                </h4>
              </div>
            </div>

            <h4 *ngIf="!enderecoSelecionado && !retirarNaLoja && !indoor" style="margin: 15px;">
              Selecione um endereço para concluir o pedido
            </h4>
          </div>

          <div *ngIf="!indoor" class="botoes">
            <button 
              class="btn_1 small botao"
              *ngIf="(enderecosCadastrados && enderecosCadastrados.length > 0) && !retirarNaLoja && !indoor"
              (click)="abrirModalSelecaoEndereco()"
            >
              Selecionar
            </button>
            <button 
              *ngIf="!retirarNaLoja && !indoor" 
              class="btn_1 small botao"
              (click)="abrirModalAdicionarEndereco()"
            >
              Adicionar
            </button>
          </div>

        </div>

        <div *ngIf="!indoor" class="box_order_form" style="margin-top: 10px;">
          <app-pagamento></app-pagamento>
        </div>

        <!-- /box_order_form -->
      </form>
    </div>

    <!-- /col -->
    <div class="col-xl-4 col-lg-4" id="sidebar_fixed">
      <app-resumo-pedido [atualizaListaEnderecos]="atualizaListaEnderecos">
      </app-resumo-pedido>
      <!-- /box_booking -->
    </div>

  </div>
  <!-- /row -->
</div>
