import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndoorService {

  public indoor: boolean = null;
  public numeroMesa: string = null;
  public usaComanda: boolean = null;
  public comanda: string = null;
  public distanciaPermitida: boolean = null;

  constructor(
    private dbService: NgxIndexedDBService,
  ) { 

    if(this.usaComanda){
      this.dbService.getAll('comanda')
      .subscribe((item: any) => {
        this.comanda = JSON.parse(item[0]?.item); 
      });
    } else {
      this.dbService.getAll('mesa')
      .subscribe((item: any) => {
        this.numeroMesa = JSON.parse(item[0].item);
      });
    }
    
  };

  vincularMesa(numeroMesa){
    this.dbService
      .add('mesa', { item: JSON.stringify(numeroMesa)})
      .subscribe((item: any) => {
        this.numeroMesa = JSON.parse(item.item);
      });
  }

  desvincularMesa(){
    if(this.numeroMesa){
      this.dbService.clear('mesa').subscribe((itens) => {
        this.numeroMesa = null
      });
    }
  }

  vincularComanda(comanda){
    this.dbService
      .add('comanda', { item: JSON.stringify(comanda) })
      .subscribe((item: any) => {
        this.comanda = JSON.parse(item.item);
      });
  }

  desvincularComanda(){
    if(this.numeroMesa){
      this.dbService.clear('comanda').subscribe((itens) => {
        this.comanda = null
      });
    }
  }

  limparVinculosMesa(){
    this.dbService.clear('mesa').subscribe((successDeleted) => {
    });
  }

  limparVinculosComanda(){
    this.dbService.clear('comanda').subscribe((successDeleted) => {
    });
  }
}
