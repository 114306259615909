import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalCadastroEnderecoComponent } from '@app/_components/cliente/endereco-entrega/modal-cadastro-endereco/modal-cadastro-endereco.component';  
import { ModalLoginComponent } from '@app/_components/cliente/modal-login/modal-login.component';
import { ModalResumoPedidoComponent } from '@app/_components/pedido/modal-resumo-pedido/modal-resumo-pedido.component';
import { FormaRetiradaPedido } from '@app/_enums/forma-retirada-pedido';
import { DetalhesCliente } from '@app/_models/cliente/detalhes-cliente';
import { DetalhesLoja } from '@app/_models/loja/detalhes-loja';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { IndoorService } from '@app/_services/indoor/indoor.service';
import { LoginService } from '@app/_services/login/login.service';
import { SacolaService } from '@app/_services/sacola/sacola.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalQrcodeComponent } from '@app/_components/pedido/modal-qrcode/modal-qrcode.component'; 
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  rotaAtualHome: boolean = true;
  topoPagina: boolean = true;
  telaConclusaoPedido: boolean;
  endereco = null;
  cliente: DetalhesCliente;
  restaurante: DetalhesLoja;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private sacolaService: SacolaService,
    private loginService: LoginService,
    private indoorService: IndoorService,
    private clienteService: ClienteService,
    public sanitizer: DomSanitizer,
    private toastr: ToastrService,
  ) {
    router.events
      .subscribe((event: any) => {
        this.telaConclusaoPedido = event.url == '/pedido';
      });

    this.loginService.cliente.subscribe(cliente => {
      this.cliente = cliente;
    });

    this.telaConclusaoPedido = this.router.url == '/pedido';
    this.endereco = this.clienteService.enderecoSelecionadoSubject;
    this.rotaAtualHome = false;
    this.restaurante = loginService.lojaLogada;
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    this.topoPagina = window.scrollY == 0;
  }

  ngOnInit(): void { }

  abrirModalLogin(): void {
    document.body.style.position = 'fixed';

    const modalRef = this.modalService.open(ModalLoginComponent, { scrollable: true });

    modalRef.result.then((e) => {
      document.body.style.position = '';
    }).catch((e) => {
      document.body.style.position = '';
    });
  }

  logout() {
    this.loginService.logout();
  }

  abrirModalResumo() {
    document.body.style.position = 'fixed';

    const modalRef = this.modalService.open(ModalResumoPedidoComponent);
  
    modalRef.result.then((e) => {
      document.body.style.position = '';
    }).catch((e) => {
      document.body.style.position = '';
    });
  }

  abrirModalMetodoEntrega() {
    document.body.style.position = 'fixed';

    let opcoesModal: NgbModalOptions = {
      keyboard: false,
      size: 'xl',
      scrollable: true
    };

    const modalRef = this.modalService.open(ModalCadastroEnderecoComponent, opcoesModal);

    modalRef.result.then((result) => {
      document.body.style.position = '';
    }).catch((error) => {
      document.body.style.position = '';
    });
    
  }

  abrirModalLeitorQR(){
    if(this.comanda){
      this.toastr.error("Não é possível alterar a mesa com comandas vinculadas.", "Não foi possível concluir a operação", {
        positionClass: 'toast-bottom-left',
      });
    } else {
      document.body.style.position = 'fixed';

      const modalRef = this.modalService.open(ModalQrcodeComponent);
      modalRef.componentInstance.tipoLeitura = "Mesa";
  
      modalRef.result.then((e) => {
        
        document.body.style.position = '';
        
        if( this.numeroMesa ) {
          this.router.navigate(['/indoor/Mesa' + this.numeroMesa]);
        }
  
      }).catch((e) => {
        document.body.style.position = '';
      });
    }
  }

  get deveMostrarNavFixed(): boolean {
    return !this.rotaAtualHome || (this.rotaAtualHome && !this.topoPagina);
  }

  get quantidadeItensSacola() {
    return this.sacolaService.totalItensSacola;
  }

  get logo() {
    if (this.lojaLogada && this.lojaLogada.ImagemLogo) {
      let imagem = this.lojaLogada.ImagemLogo;
      imagem = "data:image/png;base64," + encodeURI(imagem);
      return this.sanitizer.bypassSecurityTrustUrl(imagem);;
    }
    return '';
  }

  get usuarioLogado() {
    return this.loginService.consumidorLogadoToken ? this.loginService.clienteEstaLogado : null;
  }

  get lojaLogada() {
    return this.loginService.lojaLogada;
  }

  get isMobile() {
    return window.innerWidth < 992;
  }

  get enderecoPadrao() {
    return this.clienteService?.enderecoSelecionadoSubject;
  }

  get indoor() {
    return this.indoorService.indoor;
  }

  get pedidoEhRetiradaBalcao(){
    return this.clienteService.selecionarFormaRetirada != FormaRetiradaPedido.Entrega;
  }

  get comanda() {
    return this.indoorService.comanda;
  }

  get numeroMesa() {
    return this.indoorService.numeroMesa;
  }
}
