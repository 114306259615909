import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ClienteService } from "@app/_services/cliente/cliente.service";
import { IndoorService } from "@app/_services/indoor/indoor.service";
import { LoginService } from "@app/_services/login/login.service";
import { SacolaService } from "@app/_services/sacola/sacola.service";
import { TipoPagamentoService } from "@app/_services/tipo-pagamento/tipo-pagamento.service";
import { TipoPagamento } from "@app/_models/tipo-pagamento/tipo-pagamento";
import { FormaPagamento } from "@app/_enums/forma-pagamento";
import { FormaRetiradaPedido } from "@app/_enums/forma-retirada-pedido";
import { DetalhesCartaoCredito } from "@app/_models/cliente/cartao/detalhes-cartao-credito";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from "rxjs";
import { ChangeDetectorRef } from '@angular/core';
import { ModalEditCartaoComponent } from '@app/_components/cliente/cartao-credito/modal-edit-cartao/modal-edit-cartao.component';
import { ModalSelecaoCartaoComponent } from '@app/_components/cliente/cartao-credito/modal-selecao-cartao/modal-selecao-cartao.component';
import { ModalMensagemComponent } from "@app/_components/compartilhados/modal-mensagem/modal-mensagem.component";
import { ModalTrocoComponent } from "../pedido/modal-troco/modal-troco.component";

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.scss']
})
export class PagamentoComponent {
    
    cartoesCadastrados: DetalhesCartaoCredito[];
    cartaoSelecionado: DetalhesCartaoCredito;
    bandeiraCartaoSelecionado: string;

    form: UntypedFormGroup;
      
    cartaoRemovido = new BehaviorSubject<DetalhesCartaoCredito>(null);
    atualizaListaCartoes = new BehaviorSubject<DetalhesCartaoCredito>(null);
    
    tiposPagamento: TipoPagamento[];
    pagarNaEntrega = true;
    troco = 0;
    
    restaurantePermitePagamentoEntrega: boolean;
    restaurantePermitePagamentoOnline: boolean;
    pagamentoViaCartao: boolean;
    
    tipoPix: TipoPagamento;
    tipoCartao: TipoPagamento;

    retirarNaLoja: boolean = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private tipoPagamentoService: TipoPagamentoService,
        public loginService: LoginService,
        private clienteService: ClienteService,
        public sacolaService: SacolaService,
        public modalService: NgbModal,
        private changeDetectorRef: ChangeDetectorRef,
        public indoorService: IndoorService,
    ) {
        this.criaForm();
        this.listaCartoes();
        this.removerCartao();
    
        switch (loginService.lojaLogada.ModoPagamento) {
          case "Pagamento na entrega":
            this.sacolaService.formaPagamento = FormaPagamento.Entrega;
            this.restaurantePermitePagamentoEntrega = true;
            if(loginService.lojaLogada.HabilitarPix){
              this.restaurantePermitePagamentoOnline = true;
            }
            break;
          case "Pagamento online e na entrega":
            this.pagamentoViaCartao = true;
            this.restaurantePermitePagamentoOnline = true;
            this.restaurantePermitePagamentoEntrega = true;
            break;
          default:
            this.pagamentoViaCartao = true;
            this.restaurantePermitePagamentoOnline = true;
            this.sacolaService.formaPagamento = FormaPagamento.Loja;
            break;
        }
    
        if(loginService.lojaLogada.HabilitarPix){
          this.tipoPix = {
            Codigo: 26,
            Nome: "Pix",
            Descricao: "Pague com Pix",
            PermitirTroco: false,
            ConsultarCredito: false,
            RefCount: 0,
            Imagem: null,
            Online: true
          }
        }
    
        if(this.restaurantePermitePagamentoOnline){
          this.tipoCartao = {
            Codigo: 23,
            Nome: "Cartão de crédito",
            Descricao: "Pague com cartão de crédito",
            PermitirTroco: false,
            ConsultarCredito: true,
            RefCount: 0,
            Imagem: null,
            Online: true
          }
        }

        this.atualizaListaCartoes.subscribe(e => {
            if (e) {
              this.cartaoSelecionado = e;
              this.bandeiraCartao(this.cartaoSelecionado.Bandeira);
              this.listaCartoes();
            }
        })

        this.tipoPagamentoService.listaFormaPagamento().subscribe(e => {
            this.tiposPagamento = e.value;
            this.tipoPagamentoService.listaImagensFormaPagamento().subscribe(result => {
              let imagens = result.value;
              this.tiposPagamento.forEach(tipo => {
                let imagem = imagens.find(i => i.Codigo == tipo.Codigo && i.Imagem);
                if (imagem) {
                  tipo.Imagem = "data:image/png;base64," + imagem.Imagem;
                }
              });
            });
        });

        if(!this.indoor){
            this.clienteService.formaRetirada.subscribe(forma => {
                this.retirarNaLoja = forma == FormaRetiradaPedido.Balcao;
            })
        }
    }

    criaForm() {
        this.form = this.formBuilder.group({
          formaPagamento: [],
          nomeCartao: [],
          numeroCartao: [],
          mesDataExpiracao: [],
          anoDataExpiracao: [],
          cvv: []
        });
    }

    abrirModalSelecaoCartao() {
        document.body.style.position = 'fixed';
    
        const modalRef = this.modalService.open(ModalSelecaoCartaoComponent, { size: 'lg', scrollable: true });
        modalRef.componentInstance.cartoesCadastrados = this.cartoesCadastrados;
        modalRef.componentInstance.cartaoRemovido = this.cartaoRemovido;
    
        modalRef.result.then((cartao: DetalhesCartaoCredito) => {
          this.listaCartoes();
          if (cartao) {
            this.cartaoSelecionado = cartao;
            this.bandeiraCartao(this.cartaoSelecionado.Bandeira);
            this.sacolaService.cartaoSelecionado = this.cartaoSelecionado;
          }
          document.body.style.position = '';
        }).catch((err) => { 
          document.body.style.position = '';
        });
      }
    
      abrirModalAdicionarCartao() {
        document.body.style.position = 'fixed';
    
        const modalRef = this.modalService.open(ModalEditCartaoComponent, { size: 'lg', scrollable: true });
    
        modalRef.result.then((cartao: DetalhesCartaoCredito) => {
    
          if (cartao) {
            this.listaCartoes(cartao);
          } else {
            this.listaCartoes();
          }
          
          this.changeDetectorRef.detectChanges();
    
          document.body.style.position = '';
        }).catch((err) => {
          this.listaCartoes();
          this.changeDetectorRef.detectChanges();
          document.body.style.position = '';
        });
      }
    
      private removerCartao() {
        this.cartaoRemovido.subscribe(cartaoRemovido => {
          if (cartaoRemovido) {
            if (this.cartaoSelecionado.Codigo == cartaoRemovido.Codigo) {
              this.cartaoSelecionado = null;
              this.bandeiraCartaoSelecionado = null;
              this.sacolaService.cartaoSelecionado = null;
            }
            this.listaCartoes();
          }
        });
      }

    public formaPagamentoAlterada(aba) {
        this.troco = 0;
        this.sacolaService.valorTroco = 0;
        if (aba.tab.textLabel == "Pague na entrega") {
          this.pagarNaEntrega = true;
          this.sacolaService.formaPagamento = FormaPagamento.Entrega;
        } else {
          this.pagarNaEntrega = false;
          this.sacolaService.formaPagamento = FormaPagamento.Loja;
        }
    }
    
    private listaCartoes(cartao: DetalhesCartaoCredito = null) {
        this.clienteService.listaCartaoCredito().subscribe(cartoes => {
          
          this.cartoesCadastrados = cartoes.value;
    
          if (this.cartoesCadastrados && this.cartoesCadastrados.length > 0 && !cartao) {
            this.cartaoSelecionado
              = this.cartoesCadastrados.filter(e => e.Principal)[0] || this.cartoesCadastrados[0];
    
            this.bandeiraCartao(this.cartaoSelecionado.Bandeira);
            this.sacolaService.cartaoSelecionado = this.cartaoSelecionado;
          } else if(this.cartoesCadastrados && this.cartoesCadastrados.length > 0 &&  cartao){
            let cartaoSelecionado = this.cartoesCadastrados.find(e => e.Codigo == cartao.Codigo)
            
            this.cartaoSelecionado = cartaoSelecionado;
            this.bandeiraCartao(cartaoSelecionado.Bandeira);
            this.sacolaService.cartaoSelecionado = cartaoSelecionado;
    
            this.changeDetectorRef.detectChanges();
          } else {
            this.cartaoSelecionado = null;
          }
        });
    }
    
    formaPagamentoSelecionada(tipo) {
        this.sacolaService.tipoPagamentoSelecionado = tipo;
        this.troco = 0;
        this.sacolaService.valorTroco = 0;

        if (tipo.PermitirTroco) {
            document.body.style.position = 'fixed';
            
            const modalRef = this.modalService.open(ModalMensagemComponent);
            modalRef.componentInstance.titulo = tipo.Nome;
            modalRef.componentInstance.centralizarTexto = true;
            modalRef.componentInstance.mensagem = "Você vai precisar de troco?";
            modalRef.componentInstance.textoBotaoConfirmar = "Sim";
            modalRef.componentInstance.mostrarBotaoCancelar = true;
            modalRef.componentInstance.textoBotaoCancelar = "Não";

            modalRef.result.then((precisaTroco: boolean) => {
            if (precisaTroco) {
                const trocoModalRef = this.modalService.open(ModalTrocoComponent);
                trocoModalRef.result.then((troco: number) => {
                if (troco) {
                    this.troco = troco;
                    this.sacolaService.valorTroco = this.troco;
                }
                }).catch((err) => {});
            }
            document.body.style.position = '';
            }).catch((err) => {
            document.body.style.position = '';
            });
        }
    }

    bandeiraCartao(bandeira) {
    this.tipoPagamentoService.listaBandeiras().subscribe(e => {
        this.bandeiraCartaoSelecionado = e.value.find(b => b.Nome == bandeira)?.Imagem;
    });
    }

    get indoor() {
        return this.indoorService.indoor;
    }
}