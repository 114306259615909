<div class="imagem"></div>

<div id="register_bg">

  <div id="register">
    <aside>
      <div>
        <div style="text-align: center; margin-bottom: 10px;">
          <button title="Voltar" type="button" class="mfp-back" routerLink="/"></button>
          <h4>Faça seu login</h4>
        </div>
      </div>

      <figure>
        <a routerLink="/"><img [src]="logo" width="auto" height="54" alt=""></a>
      </figure>

      <div style="text-align: center;">
        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/login.svg" alt="Pessoa parada segurando um celular, ao fundo um celular grande. Na tela, um ícone de foto com a palavra 'login', campos de login e senha, e abaixo um botão de login. Sobre o celular, um cadeado." width="250px">
      </div>

      <div *ngIf="!mostrarCelular && !emailPendenteConfirmacao" class="sign-in-wrapper" style="color: black;">
        <a *ngIf="restaurante && restaurante.CodAppFacebook" (click)="logarComFacebook()" class="social_bt facebook">Entrar
          com Facebook</a>
        <a *ngIf="restaurante && restaurante.CodAppGoogle" (click)="logarComGoogle()" class="social_bt google">Entrar
          com Google</a>
        <input (click)="inserirEmailCelular()" type="submit" value="Entrar com e-mail ou celular"
          class="btn_1 full-width mb_5">
      </div>


      <div *ngIf="entrarComEmailCelular && !authFacebook && !emailPendenteConfirmacao" [formGroup]="form">
        <p>Informe o seu e-mail ou número de celular para continuar</p>
        <div>
          <label>E-mail ou número de celular</label>
          <input #identificacao type="email" autocapitalize="off" class="form-control" formControlName="Identificacao"
            (keyup.enter)="validarEmail();">
          <small *ngIf="!emailCelularValido && emailCeluarCampo.touched && !emailCelularVazio">E-mail ou número de celular inválido</small>

          <div class="form-group">
            <label autofocus style="margin-top: 10px;">Senha</label>
            <input autocapitalize="off" type="password" class="form-control"
              formControlName="Senha" [type]="mostrarSenha ? 'text' : 'password'" #senha (keyup.enter)="entrar();">
              <mat-icon class="material-icons-outlined" style="font-size: 1.5rem;" (click)="alterarVisibildadeSenha()">{{ mostrarSenha ? 'visibility_off' : 'visibility'}}</mat-icon>
            <small (click)="enviarEmailRecuperarSenha()" style="cursor:pointer;">Esqueci minha
              senha</small>
          </div>

          <div *ngIf="erro" class="erro">{{ erro }}</div>

          <button *ngIf="!mostrarCampoSenha || !emailCelularValido" [disabled]="!emailCelularValido"
            style="margin-top: 20px;" class="btn_1 full-width" (click)="validarEmail()">Continuar</button>
          <button *ngIf="mostrarCampoSenha && emailCelularValido" [disabled]="!emailCelularValido || senhaInvalida"
            style="margin-top: 20px;" class="btn_1 full-width" (click)="entrar()">Entrar</button>
        </div>
      </div>

      <div *ngIf="emailPendenteConfirmacao">
        Conta não confirmada.
        <a style="cursor: pointer;" class="menu_item" (click)="enviarEmailConfirmacao()">Reenviar e-mail de confirmação</a>
      </div>

      <div *ngIf="mostrarCelular" [formGroup]="formFacebook" style="color: black;">
        <p style="color: black;">Informe o seu celular</p>
        <div style="color: black;">
          <label>Celular</label>
          <input class="form-control" formControlName="celular" [mask]="mascaraCelular">
        </div>
        <button style="margin-top: 20px;" class="btn_1 full-width"
          (click)="realizaLoginCadastroRedeSocial()">Salvar</button>
      </div>
    </aside>
  </div>
</div>
