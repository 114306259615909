<div class="container">
    <div class="row justify-content-center box_order_form">
        <div class="head">
            <div class="title">
            <h3>Método de pagamento</h3>
            </div>
        </div>

        <div class="main" style="color: black;" class="endereco">
            <mat-tab-group (selectedTabChange)="formaPagamentoAlterada($event)">
            <mat-tab label="Pague pelo site" *ngIf="!indoor && restaurantePermitePagamentoOnline" (click)="formaPagamentoAlterada($event.index)">
                <ng-template matTabContent>
                
                <div *ngIf="loginService.lojaLogada.HabilitarPix" style="position: relative;">
                    <div (click)="formaPagamentoSelecionada(tipoPix)" 
                    [class.selecionado]="sacolaService.tipoPagamentoSelecionado 
                    && sacolaService.tipoPagamentoSelecionado.Codigo == tipoPix.Codigo && sacolaService.tipoPagamentoSelecionado.Online"  
                    class="endereco" style="display: flex; height: 70px;">
                    <mat-icon class="material-icons-outlined icon">pix</mat-icon>
                    <div>
                        <h4>Pix</h4>  
                    </div>
                    <mat-icon *ngIf="sacolaService.tipoPagamentoSelecionado 
                    && sacolaService.tipoPagamentoSelecionado.Codigo == tipoPix.Codigo && sacolaService.tipoPagamentoSelecionado.Online" class="material-icons-outlined icon_done_card">done</mat-icon>
                    </div>
                </div>

                <div *ngIf="pagamentoViaCartao" style="position: relative;">
                    <div (click)="abrirModalAdicionarCartao()"
                    class="endereco" style="display: flex; height: 70px;">
                    <div>
                        <mat-icon class="material-icons-outlined icon">credit_card</mat-icon>
                    </div>
                    <div>
                        <h4>Selecionar cartão de crédito</h4>  
                    </div>
                    </div>
                </div>

                <div *ngIf="cartaoSelecionado && pagamentoViaCartao" style="position: relative;">
                    <div (click)="formaPagamentoSelecionada(tipoCartao)"
                    [class.selecionado]="sacolaService.tipoPagamentoSelecionado 
                    && sacolaService.tipoPagamentoSelecionado.Codigo == tipoCartao.Codigo"   
                    class="endereco" style="display: flex; height: 70px;">
                    <div>
                        <img *ngIf="bandeiraCartaoSelecionado" class="imagem-pagamento" [src]="bandeiraCartaoSelecionado" alt="Ícone de forma de pagamento" />
                        <mat-icon *ngIf="!bandeiraCartaoSelecionado" class="material-icons-outlined icon">credit_card</mat-icon>
                    </div>
                    <div>
                        <h4>{{ cartaoSelecionado.Apelido ? cartaoSelecionado.Apelido : cartaoSelecionado.NumeroCartao }}</h4>  
                    </div>
                    <mat-icon *ngIf="sacolaService.tipoPagamentoSelecionado 
                    && sacolaService.tipoPagamentoSelecionado.Codigo == tipoCartao.Codigo" class="material-icons-outlined icon_done_card">done</mat-icon>
                    </div>
                </div>
                </ng-template>
            </mat-tab>
            <mat-tab label="Pague na entrega" *ngIf="(!retirarNaLoja || loginService.lojaLogada.HabilitarPagamentoRetira)
            && tiposPagamento 
            && tiposPagamento.length > 0
            && restaurantePermitePagamentoEntrega">
                <ng-template matTabContent>
                <div *ngFor="let tipo of tiposPagamento">
                    <div [class.selecionado]="sacolaService.tipoPagamentoSelecionado 
                    && sacolaService.tipoPagamentoSelecionado.Codigo == tipo.Codigo && !sacolaService.tipoPagamentoSelecionado.Online" class="endereco"
                    (click)="formaPagamentoSelecionada(tipo)" style="display: flex; height: 70px;">
                    <div style="display: inline-block; width: 40px;">
                        <img style="margin-right: 10px;" *ngIf="tipo.Imagem" [alt]="tipo.Nome" [src]="tipo.Imagem" width="30"
                        height="30">
                        <mat-icon *ngIf="!tipo.Imagem" class="material-icons-outlined imagem-pagamento">
                        local_atm
                        </mat-icon>
                    </div>
                    <div style="display: inline-block; width: 100%;">
                        <div><h4>{{ tipo.Nome }}</h4></div>
                        <h5 *ngIf="troco > 0 && tipo.PermitirTroco">Troco para {{ troco | currency:'BRL' }}</h5>
                    </div>
                    
                    <mat-icon 
                        *ngIf="sacolaService.tipoPagamentoSelecionado 
                        && sacolaService.tipoPagamentoSelecionado.Codigo == tipo.Codigo && !sacolaService.tipoPagamentoSelecionado.Online" 
                        class="material-icons-outlined icon_done"
                    >
                        done
                    </mat-icon>
                    </div>

                </div>
                </ng-template>
            </mat-tab>
            </mat-tab-group>
        </div>
    </div> 
</div>