import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VendedorService {

  public vendedorInformado: boolean = null;
  public numeroVendedor: string = null;

  constructor(
    private dbService: NgxIndexedDBService,
  ) { 
    if(this.vendedorInformado){
      this.dbService.getAll('vendedor')
      .subscribe((item: any) => {
        this.numeroVendedor = JSON.parse(item[0].item); 
      });
    }
  };

  vincularVendedor(numeroVendedor){
    this.dbService
      .add('vendedor', { item: JSON.stringify(numeroVendedor), vendedor: JSON.stringify(numeroVendedor) })
      .subscribe((item: any) => {
        this.numeroVendedor = JSON.parse(item.item);
      });
  }

  desvincularVendedor(){
      if(this.numeroVendedor){
      this.dbService.clear('vendedor').subscribe((itens) => {
          this.numeroVendedor = null
      });
    }
  }

  limparVinculosVendedor(){
    this.dbService.clear('vendedor').subscribe((successDeleted) => {
    });
  }
}

