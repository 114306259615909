import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service'; 

@Injectable()
export class TipoPagamentoService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  listaBandeiras() {
    return this.http.get<any>(this.API_URL + 'tipoPagamento/bandeira/tipo/crédito');
  }

  buscaBandeiraPorNumeroCartao(numeroCartao: string) {
    return this.http.get<any>(this.API_URL + 'tipoPagamento/bandeira/cartao/' + numeroCartao);
  }

  listaFormaPagamento() {
    return this.http.get<any>(this.API_URL + 'tipoPagamento/0');
  }

  listaImagensFormaPagamento() {
    return this.http.get<any>(this.API_URL + 'tipoPagamento/imagem/0');
  }
}
