import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalSelecaoEnderecoEntregaComponent } from '@app/_components/cliente/endereco-entrega/modal-selecao-endereco-entrega/modal-selecao-endereco-entrega.component';
import { FormaRetiradaPedido } from '@app/_enums/forma-retirada-pedido';
import { Endereco } from '@app/_models/endereco/endereco';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { IndoorService } from '@app/_services/indoor/indoor.service';
import { LoginService } from '@app/_services/login/login.service';
import { SacolaService } from '@app/_services/sacola/sacola.service';
import { UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ModalCadastroEnderecoComponent } from '@app/_components/cliente/endereco-entrega/modal-cadastro-endereco/modal-cadastro-endereco.component';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.scss']
})
export class PedidoComponent implements OnInit {

  form: UntypedFormGroup;

  enderecoSelecionado: Endereco;

  cliente;

  enderecosCadastrados: Endereco[] = [];
  enderecoLoja: string;

  tituloEndereco: string = "Endereço de entrega";
  retirarNaLoja: boolean = false;

  enderecoRemovido = new BehaviorSubject<Endereco>(null);
  atualizaListaEnderecos = new BehaviorSubject<Endereco>(null);

  constructor(
    private modalService: NgbModal,
    public loginService: LoginService,
    private clienteService: ClienteService,
    public indoorService: IndoorService,
    public sacolaService: SacolaService,
    public sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.enderecoLoja = loginService.lojaLogada.EnderecoCompleto;

    this.listaEnderecos();
    this.removeEndereco();

    this.atualizaListaEnderecos.subscribe((e: Endereco) => {
      if (e) {
        this.enderecoSelecionado = e;
        this.sacolaService.selecionarEndereco(this.enderecoSelecionado);
        this.listaEnderecos();
      }
    })

    if (this.indoor && this.indoorService.numeroMesa) {
      this.tituloEndereco = "Receber pedido";
    } else if (this.indoor && !this.indoorService.numeroMesa) {
      this.tituloEndereco = "Endereço da loja";
    } else {
      this.clienteService.formaRetirada.subscribe(forma => {
        this.retirarNaLoja = forma == FormaRetiradaPedido.Balcao;

        if (forma == FormaRetiradaPedido.Balcao) {
          this.tituloEndereco = "Endereço de retirada";
        } else {
          this.tituloEndereco = "Endereço de entrega";
          if (this.enderecosCadastrados && this.enderecosCadastrados.length > 0) {
            this.clienteService.enderecoSelecionado.subscribe(e => {
              if (!this.enderecoSelecionado) {
                this.enderecoSelecionado = e ? e : this.enderecosCadastrados.filter(e => e.Principal)[0] || this.enderecosCadastrados[0];
              } else if (e.Codigo != this.enderecoSelecionado.Codigo) {
                this.sacolaService.selecionarEndereco(this.enderecoSelecionado);
              }
            });
          }
        }
      });
    }

  }

  ngOnInit(): void {
    this.loginService.cliente.subscribe((cliente: any) => {
      if (cliente) {
        this.cliente = cliente;
      }
    });

    this.clienteService.enderecoAtualizado.subscribe(() => {
      this.listaEnderecos();
      this.changeDetectorRef.detectChanges();
    });
  }

  abrirModalSelecaoEndereco() {
    document.body.style.position = 'fixed';

    const modalRef = this.modalService.open(ModalSelecaoEnderecoEntregaComponent, { size: 'xl', scrollable: true });
    modalRef.componentInstance.enderecosCadastrados = this.enderecosCadastrados;
    modalRef.componentInstance.enderecoRemovido = this.enderecoRemovido;

    modalRef.result.then((endereco: Endereco) => {
      this.listaEnderecos();

      if (endereco) {
        this.enderecoSelecionado = endereco;
        this.sacolaService.selecionarEndereco(this.enderecoSelecionado);
      } else {
        this.enderecoSelecionado = null;
      }
      document.body.style.position = '';
    }).catch((err) => { 
      document.body.style.position = '';
    });
  }

  abrirModalAdicionarEndereco() {
    document.body.style.position = 'fixed';

    const modalRef = this.modalService.open(ModalCadastroEnderecoComponent, { size: 'xl' });
    modalRef.componentInstance.step = 1;

    modalRef.result.then((endereco: Endereco) => {
      this.listaEnderecos();

      if (endereco) {
        this.enderecoSelecionado = endereco;
        this.sacolaService.selecionarEndereco(this.enderecoSelecionado);
      } else {
        this.enderecoSelecionado = null;
      }
      document.body.style.position = '';
    }).catch((err) => { 
      document.body.style.position = '';
    });
  }

  removeEndereco() {
    this.enderecoRemovido.subscribe(endereco => {
      if (endereco) {
        if (this.enderecoSelecionado.Codigo == endereco.Codigo) {
          this.enderecoSelecionado = null;
          this.sacolaService.selecionarEndereco(null);
        }
        this.listaEnderecos();
      }
    })
  }

  private listaEnderecos() {
    this.clienteService.listaEnderecos().subscribe(enderecos => {
      this.enderecosCadastrados = enderecos.value;
      if (this.enderecosCadastrados && this.enderecosCadastrados.length > 0 && !this.enderecoSelecionado) {
        this.enderecoSelecionado = this.enderecosCadastrados.filter(e => e.Principal)[0]
          || this.enderecosCadastrados[0];

        this.sacolaService.selecionarEndereco(this.enderecoSelecionado);
      }
      this.changeDetectorRef.detectChanges();
    })
  }

  get indoor() {
    return this.indoorService.indoor;
  }

  get numeroMesa() {
    if (this.indoorService.numeroMesa) {
      return this.indoorService.numeroMesa;
    }
    return null;
  }

}
