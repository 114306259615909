import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CartaoCredito } from '@app/_models/cliente/cartao/create-cartao-credito';
import { Bandeira } from '@app/_models/tipo-pagamento/bandeira';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { TipoPagamentoService } from '@app/_services/tipo-pagamento/tipo-pagamento.service'; 
import { AnoValidator } from '@app/_validators/ano-validator';
import { MesValidator } from '@app/_validators/mes-validator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditCardValidators } from 'angular-cc-library';
import { MatStepper } from '@angular/material/stepper';
import { DetalhesCartaoCredito } from '@app/_models/cliente/cartao/detalhes-cartao-credito';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-edit-cartao',
  templateUrl: './modal-edit-cartao.component.html',
  styleUrls: ['./modal-edit-cartao.component.scss']
})
export class ModalEditCartaoComponent implements OnInit {

  form: UntypedFormGroup;

  cartaoCredito: CartaoCredito;
  estaAtualizando = false;
  favorito = false;
  btn_voltar = true;

  mensagemErro;
  bandeiras: Bandeira[];
  bandeiraSelecionada;
  @ViewChild('ano') public campoAno: any;

  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;

  cartoesCadastrados: DetalhesCartaoCredito[];
  cartaoRemovido: BehaviorSubject<DetalhesCartaoCredito>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private clienteService: ClienteService,
    public activeModal: NgbActiveModal,
    private tipoPagamentoService: TipoPagamentoService
  ) {
    this.listaBandeiras();

    if (!this.cartoesCadastrados) {
      this.listaCartoes();
    }

    this.form = this.formBuilder.group({
      Apelido: ['', []],
      Nome: ['', [Validators.required]],
      Numero: ['', [CreditCardValidators.validateCCNumber]],
      Mes: ['', [Validators.required, Validators.maxLength(2), MesValidator()]],
      Ano: ['', [Validators.required, Validators.maxLength(4), AnoValidator()]],
      Seguranca: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
      Principal: [false]
    });

    this.form.get('Mes').valueChanges.subscribe(e => {
      if(e.length == 2) {
        this.campoAno.nativeElement.focus();
      }
    })
  }

  ngOnInit(): void { }

  listaBandeiras() {
    this.tipoPagamentoService
      .listaBandeiras()
      .subscribe(e => {
        this.bandeiras = e.value;
      });
  }

  buscarBandeira() {
    let numeroCartao = this.form.get('Numero').value;
    numeroCartao = numeroCartao.replace(/\s/g, '');

    if (numeroCartao && numeroCartao.length == 16) {
      this.tipoPagamentoService
        .buscaBandeiraPorNumeroCartao(numeroCartao)
        .subscribe(e => {
          this.bandeiras.forEach((b, i) =>
            this.bandeiras[i].Selecionado = b.Nome == e.Nome,
            this.bandeiraSelecionada = e.Nome
          )
        });
    }
  }

  fecharModal(cartaoCredito?: DetalhesCartaoCredito) {
    this.activeModal.close(cartaoCredito);
  }

  salvarCartao() {
    let cartao: CartaoCredito = this.form.getRawValue();
    let cartaoCredito: DetalhesCartaoCredito = this.form.getRawValue();
    cartao.Numero = cartao.Numero.replace(/\s/g, '');
    cartao.Mes = cartao.Mes.padStart(2, '0');
    cartaoCredito.Bandeira = this.bandeiraSelecionada;

    this.clienteService.criaCartaoCredito(cartao).subscribe(e => {
      cartaoCredito.Codigo = e.value;
      this.fecharModal(cartaoCredito);
    });      
  }

  favoritar(){
    this.favorito = this.form.get("Principal").value;
  }

  listaCartoes() {
    this.clienteService.listaCartaoCredito().subscribe(e => {
      this.cartoesCadastrados = e.value;
    });
  }

  selecionarCartao(cartao: DetalhesCartaoCredito) {
    this.fecharModal(cartao);
  }

  marcarCartaoPrincipal(cartao: DetalhesCartaoCredito) {
    this.cartoesCadastrados.forEach(e => {
      if (e.Codigo != cartao.Codigo) {
        e.Principal = false;
      } else {
        cartao.Principal = true;
        this.clienteService.atualizaCartao(cartao).subscribe(e => {
          this.listaCartoes();
        });
      }
    });
  }

  removerCartao(cartao: DetalhesCartaoCredito) {
    Swal.fire({
      title: 'Remover',
      text: 'Você tem certeza que deseja remover o cartão?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this.clienteService.removeCartaoCredito(cartao.Codigo).subscribe(e => {
          this.cartoesCadastrados = this.cartoesCadastrados.filter(c => c.Codigo != cartao.Codigo);
          this.cartaoRemovido?.next(cartao);
        });
      }
    })
  }

  onStepChange(event: any): void{
    if(event.selectedIndex == 0 ){
      this.btn_voltar = true;
    } else {
      this.btn_voltar = false;
    }
  }

  voltarModal(stepper: MatStepper){
    stepper.previous();
  }

  avancarModal(stepper: MatStepper){
    stepper.next();
  }

  get cartaoValido() {
    return this.form.valid;
  }
}
