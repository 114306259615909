	<div id="sign-in-dialog" id="modal-dialog" ngbAutofocus>
		
		<div class="modal_header">
			<mat-icon *ngIf="entrarComEmailCelular && !emailPendenteConfirmacao" id="icon_header" class="icon-voltar material-icons-outlined" (click)="voltar()">chevron_left</mat-icon>
			<h3 *ngIf="!entrarComEmailCelular">Entrar</h3>
			<h3 *ngIf="emailPendenteConfirmacao">Confirmar e-mail</h3>
			<button class="button-close" title="Fechar" type="button" (click)="fecharModal()">
				<mat-icon id="icon-fechar" class="icon_header material-icons-outlined">close</mat-icon>
			</button>
		</div>

	  	<div class="modal_body" >
			<div style="text-align: center;">
				<img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/login.svg" alt="Pessoa parada segurando um celular, ao fundo um celular grande. Na tela, um ícone de foto com a palavra 'login', campos de login e senha, e abaixo um botão de login. Sobre o celular, um cadeado." width="250px">
			</div>
		
			<div *ngIf="!entrarComEmailCelular && !mostrarCelular" class="sign-in-wrapper">
				<a *ngIf="restaurante && restaurante.CodAppFacebook" (click)="logarComFacebook()" class="social_bt facebook">Entrar com Facebook</a>
				<a *ngIf="restaurante && restaurante.CodAppGoogle" (click)="logarComGoogle()" class="social_bt google">Entrar com Google</a>
				<button (click)="inserirEmailCelular()" class="btn_1 full-width mb_5">Entrar com e-mail ou celular</button>
		
				<div *ngIf="erro" class="erro">{{ erro }}</div>
			</div>
		
			<form [formGroup]="form" *ngIf="entrarComEmailCelular && !emailPendenteConfirmacao">
				<p>Informe o seu e-mail ou número de celular para continuar</p>
				<div>
				<label>E-mail ou número de celular</label>
				<input #identificacao type="email" autocapitalize="off" class="form-control" (change)="habilitarBotao()" formControlName="Identificacao">
		
				<small  *ngIf="!emailCelularValido && emailCeluarCampo.touched && !emailCelularVazio" class="erro">E-mail ou número de celular inválido</small>
				
				<div class="form-group">
					<label style="margin-top: 10px;">Senha</label>
					<input autocapitalize="off" type="password" class="form-control"
					formControlName="Senha" (keyup.enter)="entrar()" #senha [type]="mostrarSenha ? 'text' : 'password'">
					<mat-icon class="material-icons-outlined" style="font-size: 1.5rem;" (click)="alterarVisibildadeSenha()">{{ mostrarSenha ? 'visibility_off' : 'visibility'}}</mat-icon>
					<small (click)="enviarEmailRecuperarSenha()" style="cursor:pointer;">Esqueci minha senha</small>
				</div>
		
				<div *ngIf="erro" class="erro">{{ erro }}</div>
		
				<button *ngIf="!mostrarCampoSenha || !emailCelularValido" [disabled]="!emailCelularValido"
					class="btn_1 full-width" (click)="validarEmail()">Continuar</button>
				<button *ngIf="mostrarCampoSenha && emailCelularValido" [disabled]="!emailCelularValido || senhaInvalida"
					class="btn_1 full-width" (click)="entrar()">Entrar</button>
				</div>
			</form>
		
			<form *ngIf="mostrarCelular" [formGroup]="formRedeSocial">
				<p>Informe o seu celular</p>
				<div>
				<label>Celular</label>
				<input class="form-control" formControlName="celular" [mask]="mascaraCelular">
				</div>
				<button style="margin-top: 20px;" class="btn_1 full-width" (click)="realizaLoginCadastro()">Salvar</button>
			</form>
		
			<div *ngIf="emailPendenteConfirmacao">
				Conta não confirmada.
				<a style="cursor: pointer;" class="menu_item" (click)="enviarEmailConfirmacao()">Reenviar e-mail de confirmação</a>
			</div>
	  	</div>
	</div>
