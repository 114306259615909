export class PreparoMercadoria {
    Codigo: number;
    CodigoGrupoPreparo: number;
    CodBasico: string;
    CodBarras: string;
    CodBasicoContida: string;
    CodComposicao: number;
    Nome: string;
    Estado: string;
    CodMercInternet: number;
    PrecoVenda: number;
    Quantidade: number;
    QuantidadeMinima: number;
    QuantidadeMaxima: number;
    PermitePersonalizacao: boolean;
    ValorPersonalizacao: any;
    TipoPersonalizacao: string;
    TamanhoMinimoCampoPersonalizacao: number;
    TamanhoMaximoCampoPersonalizacao: number;
}
