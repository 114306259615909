import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, Input} from '@angular/core';
import jsQR from "jsqr";
import { VIDEO_CONFIG } from '@app/_configs/video-config';
import {Subject, takeUntil, timer} from "rxjs";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { IndoorService } from '@app/_services/indoor/indoor.service';
import { ToastrService } from 'ngx-toastr'; 
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-modal-qrcode',
  templateUrl: './modal-qrcode.component.html',
  styleUrls: ['./modal-qrcode.component.scss']
})
export class ModalQrcodeComponent implements AfterViewInit {

  @ViewChild('videoElement') video!: ElementRef<HTMLVideoElement>
  @ViewChild('canvas', {static: true}) canvas!: ElementRef
  @Input() tipoLeitura: string;

  videoStream!: MediaStream
  
  config = structuredClone(VIDEO_CONFIG)

  private destroy$ = new Subject<void>()

  result = ''
  permissaoConcedida: string = "prompt";
  toaster = false;
  intervalPermission: any;

  constructor(
    public activeModal: NgbActiveModal,
    public indoorService: IndoorService,
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}


  ngAfterViewInit(): void {
    this.intervalPermission = setInterval(() => {
      this.permissaoAsync();
    }, 700);
  }

  ngOnDestroy() {
    this.videoStream.getTracks().forEach((track) => track.stop())
    this.video = null!

    this.destroy$.next()
    this.destroy$.complete()
  }

  inverterCamera() {
    let {facingMode} = this.config.video

    this.config.video.facingMode = facingMode === 'enviroment' ? 'user' : 'enviroment'

    this.iniciarScanner();
  }

  async permissaoAsync(){
    const permissao = await this.checarPermissao();

    if( permissao ){
      this.iniciarScanner();
    }
  }

  async iniciarScanner(){
    this.videoStream = await navigator.mediaDevices.getUserMedia(this.config);
    this.video.nativeElement.srcObject =  this.videoStream;

    this.lerQrcode();
  }

  lerQrcode(){
    if (this.video.nativeElement) {
      const {clientWidth, clientHeight} = this.video.nativeElement

      this.canvas.nativeElement.width = clientWidth
      this.canvas.nativeElement.height = clientHeight

      const canvas = this.canvas.nativeElement.getContext('2d') as CanvasRenderingContext2D

      canvas.drawImage(this.video.nativeElement, 0, 0, clientWidth, clientHeight)

      const inversionAttempts = 'dontInvert'

      const image = canvas.getImageData(0, 0, clientWidth, clientHeight)
      const qrcode = jsQR(image.data, image.width, clientHeight, {inversionAttempts})

      if (qrcode) {
        if(this.tipoLeitura == "Comanda"){
          this.indoorService.desvincularComanda();
          this.indoorService.vincularComanda(qrcode.data);
          this.activeModal.close();
          this.toastr.success(`Comanda ${ qrcode.data } vinculada com sucesso.`, "Não foi possível concluir a operação", {
            positionClass: 'toast-bottom-left',
          });
        } else {
          let index = qrcode.data.indexOf("Mesa")
          let novaString = qrcode.data.slice(index + 4);
    
          this.indoorService.limparVinculosMesa();
          this.indoorService.vincularMesa(novaString);     
          this.indoorService.indoor = true;
          this.activeModal.close();
        }
      } else {
        timer(100).pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.lerQrcode();
        })
      }
    }
  }

  async checarPermissao(){
    const permissao = await navigator.permissions.query({name: 'camera'} as any)

    const estado = permissao.state !== "denied"
    this.permissaoConcedida = permissao.state;

    const hasMediaDevice = 'mediaDevices' in navigator
    const hasUserMedia = 'getUserMedia' in navigator.mediaDevices

    if (!hasMediaDevice || (!hasUserMedia && estado)) {
      this.activeModal.close();
        Swal.fire({
            title: 'Permissão negada!',
            text: 'Ops! Não conseguimos acessar sua câmera para escanear o QR Code e vincular sua comanda. Por favor, ajuste as configurações de permissão do navegador para continuar.',
            icon: 'error',
            confirmButtonText: 'Ok',
        }).then(() => {
        })
    }

    if(permissao.state != 'prompt'){
      clearInterval(this.intervalPermission);
    }

    this.changeDetectorRef.detectChanges();
    return estado;
  }

  get permissao() { 
      if (this.permissaoConcedida == "denied" && !this.toaster) {
        this.toaster = true;
        this.activeModal.close();
        Swal.fire({
          title: 'Permissão negada!',
          text: 'Ops! Não conseguimos acessar sua câmera para escanear o QR Code e vincular sua comanda. Por favor, ajuste as configurações de permissão do navegador para continuar.',
          icon: 'error',
          confirmButtonText: 'Ok',
        }).then(() => {
        })
      }

    return this.permissaoConcedida;
  }

  fecharModal() {
    this.activeModal.close();
  }

}
