<div id="sign-in-dialog" id="modal-dialog" ngbAutofocus>
		
    <div class="modal_header">
        <h3>Esqueci minha senha</h3>
        <button class="button-close" title="Fechar" type="button" (click)="fecharModal()">
            <mat-icon id="icon-fechar" class="icon_header material-icons-outlined">close</mat-icon>
        </button>
    </div>

      <div class="modal_body" >
    
        <form [formGroup]="form">
            <div>
                <label>E-mail de recuperação</label>
                <input autofocus #identificacao type="email" autocapitalize="off" class="form-control" formControlName="Identificacao">
                <small  *ngIf="!emailCelularValido && emailCeluarCampo.touched" class="erro">E-mail ou número de celular inválido</small>
    
    
                <button [disabled]="!emailCelularValido" class="btn_1 full-width" (click)="validarEmail()" style="margin-top: 1rem;">
                    Continuar
                </button>
            </div>
        </form>
      </div>
</div>
