import { Component, OnInit, ViewChild } from '@angular/core';
import { SplashScreenStateService } from '@app/_services/splash-screen/splash-screen-state';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { IndoorService } from '@app/_services/indoor/indoor.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})

export class SplashScreenComponent implements OnInit {
  public showSplash = true;
  currentTextLoading: string;
  rotaAtual: string;

  constructor(
    private splashScreenStateService: SplashScreenStateService,
    private router: Router,
    private IndoorService: IndoorService,
  ) {
    router.events
    .pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.rotaAtual = event.url;

      if (this.IndoorService.indoor == null) {
        this.IndoorService.indoor = this.rotaAtual.includes('/indoor');
      }

      if(this.rotaAtual == '/servidor-indisponivel'){
        this.hideSplashAnimationFast();
      }
    });
  }

  ngOnInit(): void {
    this.currentTextLoading = this.splashScreenStateService.getTextLoading();
    this.splashScreenStateService.subscribeStop(res => {
      this.hideSplashAnimation();
    });
    this.splashScreenStateService.subscribeText((res: string) => {
      this.currentTextLoading = res;
      this.startTextAnimation();
    });
  }

  @ViewChild('lottieAnimation') lottieAnimation: AnimationItem;
  options: AnimationOptions = {
    path: '../../assets/lottie/splash-loading.json',
  };

  private hideSplashAnimation() {
    setTimeout(() => {
      this.showSplash = !this.showSplash;
    }, 1000);
  }

  private hideSplashAnimationFast() {
    setTimeout(() => {
      this.showSplash = !this.showSplash;
    }, 100);
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.loop = true;
  }

  animationComplete(event: any): void {
    if (event && event.currentFrame === event.totalFrames) {
      this.lottieAnimation.playSegments([0, 0], true);
    }
  }

  startTextAnimation(): void {
    this.currentTextLoading = this.splashScreenStateService.getTextLoading();
  }
}
