import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { IndoorService } from '@app/_services/indoor/indoor.service';
import { filter } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { LoginService } from '@app/_services/login/login.service';
import { DetalhesCliente } from '@app/_models/cliente/detalhes-cliente';
import { SacolaService } from '@app/_services/sacola/sacola.service';
import { CpfCnpjValidator } from '@app/_validators/cpfCnpj-validator';
import { MesaService } from '@app/_services/mesa/mesa.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-historico-comanda',
  templateUrl: './historico-comanda.component.html',
  styleUrls: ['./historico-comanda.component.scss']
})
export class HistoricoComandaComponent implements OnInit {

  form: UntypedFormGroup;
  cpfCnpjMask: string = '00.000.000/0000-00';
  cliente: DetalhesCliente;
  itensPedidos;
  historicoComanda;

  constructor(
    private router: Router,
    private indoorService: IndoorService,
    private loginService: LoginService,
    private formBuilder: UntypedFormBuilder,
    public sacolaService: SacolaService,
    public mesaService: MesaService,
  ) { 
    this.itensPedido();
    loginService.cliente.subscribe(cliente => {
      this.cliente = cliente;
      this.cpfCnpjMask = this.cliente && this.cliente.CPFCNPJ && this.cliente.CPFCNPJ.length <= 11 ? '000.000.000-00' : '00.000.000/0000-00'
    })
  }

  ngOnInit(): void { 

    this.itensPedido();

    this.form = this.formBuilder.group({
      CPFCNPJ: [{ value: this.cliente ? this.cliente.CPFCNPJ : '', disabled: !this.cliente || !this.cliente.CPFCNPJ }, [CpfCnpjValidator.validate]],
      cpfCnpjNaNota: [this.cliente && this.cliente.CPFCNPJ]
    });

    this.atualizaMascaraCnpj();

    this.form.get('cpfCnpjNaNota').valueChanges.subscribe(cpfNaNota => {
      const campoCpfCnpj = this.form.get('CPFCNPJ');

      if (!cpfNaNota) {
        campoCpfCnpj.reset();
        campoCpfCnpj.markAsPristine();
        campoCpfCnpj.disable();
      } else {
        campoCpfCnpj.enable();
        campoCpfCnpj.setValue(this.cliente.CPFCNPJ);
      }
    });
  }

  ngOnDestroy() {
    this.desbloquearMesa();
  }

  fecharConta(){
    Swal.fire({
      title: 'Fechar conta',
      text: 'Para fechar a conta, por favor, dirija-se ao balcão ou solicite a ajuda do garçom na sua mesa.',
      icon: 'info',
      confirmButtonText: 'Ok',
    }).then((result) => {
    })
  }

  atualizaMascaraCnpj() {
    this.form.get('CPFCNPJ').valueChanges.subscribe(e => {
      this.cpfCnpjMask = e && e.length <= 11 ? '000.000.000-000' : '00.000.000/0000-00';
    });
  }

  imagemURL(item: any) {
    if (item.ImagemURL && item.ImagemURL != "https://noxmobarquivos.blob.core.windows.net/") {
      return item.ImagemURL;
    }

    return false;
  }

  calculaDesconto(precoAtual, porcentagemDesconto) {
    return porcentagemDesconto ?
      precoAtual - precoAtual * (porcentagemDesconto / 100) :
      precoAtual;
  }

  getPrecoVariacao(precoVenda, precoComDesconto, quantidade) {
    var preco = precoVenda != precoComDesconto ? precoComDesconto : precoVenda
    return preco * quantidade;
  }

  preparosComposicao(codBasicoContida: string, preparos: any[]){
    var preparosComposicao = preparos.filter(preparo => preparo.CodBasicoContida == codBasicoContida)
    return preparosComposicao;
  }
  
  itensPedido() {
    var codMesaCartao = null;

    if(this.usaComanda){
      codMesaCartao = this.comanda;
    } else {
      codMesaCartao = this.numeroMesa;
    }

    if(codMesaCartao != null){
      this.mesaService.bloquearMesa(codMesaCartao).subscribe((historicoComanda) => {
        this.historicoComanda = historicoComanda;
        this.itensPedidos = historicoComanda.Itens;
      })
    }
  }

  desbloquearMesa(){
    var codMesaCartao;

    if(this.usaComanda){
      codMesaCartao = this.comanda;
    } else {
      codMesaCartao = this.numeroMesa;
    }

    this.mesaService.desbloquearMesa(codMesaCartao);
  }

  get valorTotalPedido() {
    return this.historicoComanda?.Reservas[0]?.Total ? this.historicoComanda.Reservas[0].Total : 0;
  }

  get subtotalPedido() {
    return this.historicoComanda?.Reservas[0]?.SubTotal ? this.historicoComanda.Reservas[0].SubTotal : 0;
  }

  get indoor() { 
    return this.indoorService.indoor; 
  }

  get usaComanda(){
    return this.indoorService.usaComanda;
  }

  get numeroMesa(){
    return this.indoorService.numeroMesa;
  }

  get comanda(){
    return this.indoorService.comanda
  }

  get itensCarrinho() {
    return this.sacolaService.listarMercadorias();
  }
}

