import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResumoPedidoComponent } from './resumo-pedido/resumo-pedido.component';
import { AcompanhamentoPedidoComponent } from './acompanhamento-pedido/acompanhamento-pedido.component';
import { ModalAdicionarCarrinhoComponent } from './modal-adicionar-carrinho/modal-adicionar-carrinho.component';
import { PedidoComponent } from './pedido/pedido.component';
import { MercadoriaService } from '@app/_services/mercadoria/mercadoria.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { DropdownItensCarrinhoComponent } from './dropdown-itens-carrinho/dropdown-itens-carrinho.component';
import { MercadoriaCompostaService } from '@app/_services/mercadoria-composta/mercadoria-composta.service';
import { CarregandoModule } from '../compartilhados/carregando/carregando.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TaxaEntregaService } from '@app/_services/taxa-entrega/taxa-entrega.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { ModalEntrarContatoRestauranteComponent } from './acompanhamento-pedido/modal-entrar-contato-restaurante/modal-entrar-contato-restaurante.component';
import { PedidoService } from '@app/_services/pedido/pedido.service';
import { MatTabsModule } from '@angular/material/tabs';
import { ModalTrocoComponent } from './pedido/modal-troco/modal-troco.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatIconModule } from '@angular/material/icon';
import { TurnoService } from '@app/_services/turno/turno.service';
import { ModalResumoPedidoComponent } from './modal-resumo-pedido/modal-resumo-pedido.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ModalPixComponent } from './modal-pix/modal-pix.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ChamarGarcomComponent } from './chamar-garcom/chamar-garcom.component';
import { ModalQrcodeComponent } from './modal-qrcode/modal-qrcode.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { HistoricoComandaComponent } from './historico-comanda/historico-comanda.component';
import { MesaService } from '@app/_services/mesa/mesa.service';
import { PagamentoComponent } from './pagamento/pagamento.component';

@NgModule({
  declarations: [
    ResumoPedidoComponent,
    AcompanhamentoPedidoComponent,
    ModalAdicionarCarrinhoComponent,
    PedidoComponent,
    DropdownItensCarrinhoComponent,
    ModalEntrarContatoRestauranteComponent,
    ModalTrocoComponent,
    ModalResumoPedidoComponent,
    ModalPixComponent,
    ChamarGarcomComponent,
    ModalQrcodeComponent,
    HistoricoComandaComponent,
    PagamentoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    CarregandoModule,

    NgbModule,
    NgxMaskModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatTabsModule,
    MatIconModule,
    CurrencyMaskModule,
    MatChipsModule,
    MatTooltipModule,
    MatButtonModule,
    ClipboardModule,
    MatProgressBarModule,
    NgxScannerQrcodeModule,
  ],
  exports: [
    DropdownItensCarrinhoComponent,
    ModalAdicionarCarrinhoComponent,
    ResumoPedidoComponent
  ],
  providers: [
    MercadoriaService,
    MercadoriaCompostaService,
    TaxaEntregaService,
    PedidoService,
    TurnoService,
    MesaService,
  ]
})
export class PedidoModule { }
