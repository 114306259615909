import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '@app/_services/login/login.service';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { LogarCliente } from '@app/_models/cliente/logar-clients';
import { DomSanitizer } from '@angular/platform-browser';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import { ModalAlertService } from '@app/_services/base/alert.service';
import { CriptografaSenha } from '@app/_utills/criptografa-senha';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalResetSenhaComponent } from '../modal-reset-senha/modal-reset-senha.component';
import { DetalhesLoja } from '@app/_models/loja/detalhes-loja';
import { ToastrService } from 'ngx-toastr';
import { TipoRedeSocial } from '@app/_enums/tipo-rede-social';
import { Cliente } from '@app/_models/cliente/cliente';
import { ModalOtpComponent } from '../modal-otp/modal-otp.component';
import { OTP } from '@app/_models/cliente/otp';
import { ClienteLogado } from '@app/_models/cliente/cliente-logado';
import { SacolaService } from '@app/_services/sacola/sacola.service';
import { FormaRetiradaPedido } from '@app/_enums/forma-retirada-pedido';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('imagem', { static: false }) public imagem: ElementRef;
  @ViewChild('register', { static: false }) public registro: ElementRef;

  form: UntypedFormGroup;
  formFacebook: UntypedFormGroup;
  entrarComEmailCelular = false;
  emailCelular = '';
  mostrarCampoSenha = false;
  authFacebook = null;
  mostrarCelular = false;
  usuarioFacebook;
  erro;
  returnUrl: string;
  emailPendenteConfirmacao = false;
  email: string;
  mostrarSenha = false;
  @ViewChild('senha') campoSenha: ElementRef;
  @ViewChild('identificacao') campoIdentificacao: ElementRef;
  restaurante: DetalhesLoja;
  private authService: SocialAuthService;
  mascaraCelular = '(00) 0000-00009';
  usuario: Cliente;
  redeSocial: TipoRedeSocial;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private formBuilder: UntypedFormBuilder,
    private clienteService: ClienteService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private alertaService: ModalAlertService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private sacolaService: SacolaService,
  ) {
    this.restaurante = this.loginService.lojaLogada;
    var providers = [];

    if (this.restaurante && this.restaurante.CodAppGoogle) {
      providers.push({
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(this.restaurante.CodAppGoogle),
      });
    }

    if (this.restaurante && this.restaurante.CodAppFacebook) {
      providers.push({
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(this.restaurante.CodAppFacebook),
      });
    }

    this.authService = new SocialAuthService({ providers: providers });

    const url = this.router.url;

    if (url.includes('/Confirmacao/Consumidor')) {
      const codConsumidor = this.route.snapshot.params.codigo;
      const token = this.route.snapshot.params.token;

      this.clienteService
        .verificaEmailConfirmado(codConsumidor, token)
        .subscribe((e) => {
          this.alertaService.mostraAlerta(
            'Conta confirmada com sucesso',
            'success'
          );
        });
    }

    this.criaForm();
    this.atualizaMascaraCelular();
  }

  atualizaMascaraCelular() {
    this.formFacebook
      .get('celular')
      .valueChanges.subscribe(
        (e) =>
          (this.mascaraCelular =
            e.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000')
      );
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    if (
      this.route.snapshot.queryParams &&
      this.route.snapshot.queryParams.email
    ) {
      this.email = this.route.snapshot.queryParams.email;
    }
  }

  enviarEmailRecuperarSenha() {
    document.body.style.position = 'fixed';
    const modalRef = this.modalService.open(ModalResetSenhaComponent);

    modalRef.result
      .then((confirmar: boolean) => {
        document.body.style.position = '';
      })
      .catch((err) => {
        document.body.style.position = '';
      });
  }

  criaForm() {
    this.form = this.formBuilder.group({
      Identificacao: [this.email],
      Senha: [],
    });

    this.formFacebook = this.formBuilder.group({
      celular: [],
    });
  }

  abrirTelaCadastro() {
    this.router.navigate(['/cadastro']);
  }

  atualizarEnderecoAposLogin(){
    var endereco = this.clienteService.enderecoSelecionado.value;

    this.clienteService.insereEndereco(endereco).subscribe(e => {
      endereco.Codigo = e.value;
      this.clienteService.atualizarEnderecoSelecionadoSubject(endereco);
      this.clienteService.avisaAtualizacaoEndereco();
      this.clienteService.alterarFormaRetirada(FormaRetiradaPedido.Entrega);
      this.sacolaService.selecionarEndereco(endereco);
    });

  }

  entrar() {
    const cliente = this.form.getRawValue() as LogarCliente;
    const senha = new CriptografaSenha().criptografaSenha(cliente.Senha);
    cliente.Senha = senha;

    this.loginService.loginCliente(cliente).subscribe(
      (logado) => {
        if (logado && logado.EmailConfirmado) {
          this.atualizarEnderecoAposLogin();
          this.router.navigateByUrl(this.returnUrl);
        } else {
          this.emailPendenteConfirmacao = true;
        }
      },
      (err) => (this.erro = err)
    );
  }

  inserirEmailCelular() {
    this.entrarComEmailCelular = true;
    setTimeout(() => this.campoIdentificacao.nativeElement.focus());
  }

  enviarEmailConfirmacao() {
    this.toastr.error('Você receberá um e-mail para ativar sua conta', '', {
      positionClass: 'toast-bottom-left',
    });
    this.clienteService.confirmarEmail().subscribe();
    this.loginService.removerTokenConsumidor();
    this.router.navigate(['/']);
  }

  voltar() {
    this.entrarComEmailCelular = false;
    this.form.get('Identificacao').markAsUntouched();
  }

  validarEmail() {
    const identificacao = this.form.get('Identificacao').value;
    this.clienteService
      .verificaCadastroExiste(identificacao)
      .subscribe((existe) => {
        if (existe.value) {
          const telefoneRegex = new RegExp('^[0-9]+$');
          if (telefoneRegex.test(identificacao) && this.restaurante.PermitirLoginViaSMS) {
            const otp: OTP = {
              Numero: identificacao,
              Token: '',
            };

            this.clienteService
              .enviarSMSParaVerificacaoIdentidade(otp)
              .subscribe(
                () => {
                  document.body.style.position = 'fixed';
                  
                  const modalRef = this.modalService.open(ModalOtpComponent);
                  modalRef.componentInstance.numeroCelular = identificacao;
                  
                  modalRef.result.then((e) => {
                    document.body.style.position = '';
                  }).catch((e) => {
                    document.body.style.position = '';
                  });

                  this.router.navigateByUrl(this.returnUrl);
                },
                (err) => {
                  this.erro = 'Falha ao enviar OTP para o número informado.';
                }
              );
          }
          else{
            this.mostrarCampoSenha = true;
            setTimeout(() => this.campoSenha.nativeElement.focus(), 100);
          }
        } else {
          this.router.navigate(['cadastro'], {
            queryParams: { identificacao },
          });
        }
      });
  }

  realizaLoginCadastroRedeSocial() {
    const ref = this;

    ref.usuario.Celular = ref.formFacebook.get('celular').value;
    ref.clienteService.insere(ref.usuario).subscribe((e) => {
      ref.clienteService
        .loginRedeSocial({
          RedeSocial: ref.redeSocial,
          Token:
            ref.redeSocial == TipoRedeSocial.Facebook
              ? ref.usuario.TokenFacebook
              : ref.usuario.TokenGoogle,
          Email: ref.usuario.Email,
        })
        .subscribe((e) => {
          ref.loginService.salvaConsumidorCache(e, e.JWTTokenExpiracao);
          ref.loginService.salvarTokenConsumidor(
            e.JWTToken,
            e.JWTTokenExpiracao
          );
          this.router.navigateByUrl(this.returnUrl);
        });
    });
  }

  logarComGoogle(): void {
    const ref = this;

    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((response) => {
        ref.usuario = new Cliente(
          response.email,
          response.name,
          response.idToken,
          TipoRedeSocial.Google
        );
        ref.clienteService
          .verificaCadastroExiste(response.email)
          .subscribe((cadastrado) => {
            if (cadastrado.value) {
              ref.clienteService
                .loginRedeSocial({
                  RedeSocial: TipoRedeSocial.Google,
                  Token: response.idToken,
                  Email: ref.usuario.Email,
                })
                .subscribe(
                  (usuarioLogado) => {
                    ref.loginService.salvaConsumidorCache(
                      usuarioLogado,
                      usuarioLogado.JWTTokenExpiracao
                    );
                    ref.loginService.salvarTokenConsumidor(
                      usuarioLogado.JWTToken,
                      usuarioLogado.JWTTokenExpiracao
                    );
                    this.router.navigateByUrl(this.returnUrl);
                  },
                  (err) => (this.erro = err)
                );
            } else {
              ref.mostrarCelular = true;
              this.redeSocial = TipoRedeSocial.Google;
            }
          });
      });
  }

  logarComFacebook(): void {
    const ref = this;
    this.authService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((response) => {
        ref.usuario = new Cliente(
          response.email,
          response.name,
          response.id,
          TipoRedeSocial.Facebook
        );

        ref.clienteService
          .verificaCadastroExiste(response.email)
          .subscribe((cadastrado) => {
            if (cadastrado.value) {
              ref.clienteService
                .loginRedeSocial({
                  RedeSocial: TipoRedeSocial.Facebook,
                  Token: response.id,
                  Email: ref.usuario.Email,
                })
                .subscribe(
                  (usuarioLogado) => {
                    ref.loginService.salvaConsumidorCache(
                      usuarioLogado,
                      usuarioLogado.JWTTokenExpiracao
                    );
                    ref.loginService.salvarTokenConsumidor(
                      usuarioLogado.JWTToken,
                      usuarioLogado.JWTTokenExpiracao
                    );
                    this.router.navigateByUrl(this.returnUrl);
                  },
                  (err) => (this.erro = err)
                );
            } else {
              ref.mostrarCelular = true;
              this.redeSocial = TipoRedeSocial.Facebook;
            }
          });
      });
  }

  alterarVisibildadeSenha() {
    this.mostrarSenha = !this.mostrarSenha;
  }

  get emailCelularValido() {
    const emailRegex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
    const telefoneRegex = new RegExp('^[0-9]+$');
    this.emailCelular = this.form.get('Identificacao').value;

    return (
      this.emailCelular &&
      (emailRegex.test(this.emailCelular) ||
        (this.emailCelular.length == 11 &&
          telefoneRegex.test(this.emailCelular)))
    );
  }

  get emailCelularVazio() {
    let emailCelular = this.form.get('Identificacao').value;

    if(emailCelular == "" || emailCelular == null){
      return true
    } else {
      return false
    }
  }

  get emailCeluarCampo() {
    return this.form.get('Identificacao');
  }

  get senhaInvalida() {
    return (
      !this.form.get('Senha').value || this.form.get('Senha').value.length < 0
    );
  }

  get logo() {
    if (this.lojaLogada && this.lojaLogada.ImagemLogo) {
      let imagem = this.lojaLogada.ImagemLogo;
      imagem = 'data:image/png;base64,' + encodeURI(imagem);
      return this.sanitizer.bypassSecurityTrustUrl(imagem);
    }
    return '';
  }

  get lojaLogada() {
    return this.loginService.lojaLogada;
  }
}
