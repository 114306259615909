import { DBConfig } from "ngx-indexed-db";

export function migrationFactory() {
  // The animal table was added with version 2 but none of the existing tables or data needed
  // to be modified so a migrator for that version is not included.
  return {
    1: (db, transaction) => {
      const store = transaction.objectStore('itensPedido');
      store.createIndex('item', 'item', { unique: false });
    },
    2: (db, transaction) => {
      const store = transaction.objectStore('endereco');
      store.createIndex('item', 'item', { unique: false });
    },
    3: (db, transaction) => {
      const store  = transaction.objectStore('mesa');
      store.createIndex('item', 'item', { unique: true });
    },
    4: (db, transaction) => {
      const store  = transaction.objectStore('comanda');
      store.createIndex('item', 'item', { unique: true });
    },
    5: (db, transaction) => {
      const store  = transaction.objectStore('vendedor');
      store.createIndex('item', 'item', { unique: true });
    },
  };
}

export const dbConfig: DBConfig = {
  name: 'NoxMob',
  version: 6,
  objectStoresMeta: [
    {
      store: 'itensPedido',
      storeConfig: { keyPath: 'codigoItem', autoIncrement: true },
      storeSchema: [
        { name: 'item', keypath: 'item', options: { unique: false } },
      ]
    },
    {
      store: 'endereco',
      storeConfig: { keyPath: 'codigoEndereco', autoIncrement: true },
      storeSchema: [
        { name: 'item', keypath: 'item', options: { unique: false } },
      ]
    },
    {
      store: 'comanda',
      storeConfig: { keyPath: 'comanda', autoIncrement: false},
      storeSchema: [
        { name: 'item', keypath: 'item', options: { unique: true } },
      ]
    },
    {
      store: 'mesa',
      storeConfig: { keyPath: 'mesa', autoIncrement: false},
      storeSchema: [
        { name: 'item', keypath: 'item', options: { unique: true } },
      ]
    },
    {
      store: 'vendedor',
      storeConfig: { keyPath: 'vendedor', autoIncrement: true},
      storeSchema: [
        { name: 'item', keypath: 'item', options: { unique: true } },
      ]
    },
  ],

  migrationFactory
};
