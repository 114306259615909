import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAlertService } from '@app/_services/base/alert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-reset-senha',
  templateUrl: './modal-reset-senha.component.html',
  styleUrls: ['./modal-reset-senha.component.scss'],
})
export class ModalResetSenhaComponent {

    emailCelular;
    form: UntypedFormGroup;
    email: string;
    @ViewChild('identificacao') campoIdentificacao: ElementRef;

    constructor(
        public activeModal: NgbActiveModal,
        private clienteService: ClienteService,
        private alertaService: ModalAlertService,
        private formBuilder: UntypedFormBuilder,
    ) {
        this.form = this.formBuilder.group({
            Identificacao: [this.email],
        });
    }

    ngOnInit(): void {}

    ngAfterViewInit(){
        setTimeout(() => this.campoIdentificacao.nativeElement.focus());
    }

    fecharModal() {
        this.activeModal.close();
    }

    validarEmail() {
        const identificacao = this.form.get('Identificacao').value;
        this.clienteService
          .verificaCadastroExiste(identificacao)
          .subscribe((existe) => {
            if (existe.value) {
                this.clienteService
                .enviaEmailResetaSenha(this.form.get('Identificacao').value)
                .subscribe((e) => {
                    this.fecharModal();
                    Swal.fire({
                        text: 'Você receberá em seu e-mail as instruções para redefinir a senha.',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                    }).then(() => {
                    
                    })
                });
            } else {
                Swal.fire({
                    text: 'Ocorreu um erro ao enviar email de recuperação. Confira o email e tente novamente.',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                }).then(() => {
                
                })
            }
        });
    }
    
    get emailCelularValido() {
        const emailRegex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
        const telefoneRegex = new RegExp('^[0-9]+$');
        this.emailCelular = this.form.get('Identificacao').value;

        return (
        this.emailCelular &&
        (emailRegex.test(this.emailCelular) ||
            (this.emailCelular.length == 11 &&
            telefoneRegex.test(this.emailCelular)))
        );
    }

    get emailCeluarCampo() {
        return this.form.get('Identificacao');
    }
}
