<div id="modal-dialog" ngbAutofocus>

    <div class="modal_header">
        <h3>Escolher cartão</h3>
        <!-- <h3 *ngIf="tipoLeitura == 'Comanda'">Escolher cartão</h3>
        <h3 *ngIf="tipoLeitura == 'Mesa'">Escolher mesa</h3> -->
        <button class="button-close" title="Fechar" type="button" (click)="fecharModal()">
            <mat-icon id="icon-fechar" class="icon_header material-icons-outlined">close</mat-icon>
        </button>
    </div>

    <div class="modal_body">
        <div class="container">
            <div *ngIf="permissao == 'prompt'" class="card">
                <mat-icon class="icon-large material-icons-outlined icon-info">
                    error_outline
                </mat-icon>
                <h3>
                    Para escanear o QR Code e vincular a comanda, precisamos acessar a câmera do seu dispositivo. Por favor, clique em "Permitir" para continuar.
                </h3>
            </div>

            <div [hidden]="permissao == 'prompt'">
                <button *ngIf="permissao != 'prompt'" class="button" mat-mini-fab>
                    <a (click)="inverterCamera()">
                      <mat-icon class="material-icons-outlined">flip_camera_ios</mat-icon>
                    </a>
                </button>
                <video #videoElement autoplay playsinline> </video>
                <div class="video--container" style="display: none">
                    <canvas id="canvas" #canvas></canvas>
                </div>
            </div>
        </div>
    </div>
</div>